import React, { useState } from "react";
import {
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const FAQS = () => {
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Grid item xs={12} pb={2}>
      {[
           {
                question: "How do I make an appointment?",
                answer: (
                <>
                Existing clients can schedule appointments using their patient portal by clicking the <strong>Schedule</strong> button in the top right. New clients may also click this button and then go to <strong>New Client</strong> to fill out some initial forms.
                 <br /><br />
            You can also schedule with us by emailing <a href="mailto:reception@northlandpsychological.com">reception@northlandpsychological.com</a> or calling our office at <a href="tel:+12187296480">(218) 729-6480</a>.
                </>
                )
            },
            {
    question: "What can I expect?",
    answer: (
      <>
        Your initial session is called a <strong>Diagnostic Intake</strong>. This session is used to gather your history and for the therapist to get a full understanding of what is currently going on in your life.
        <br /><br />
        When starting therapy, sessions are traditionally on a weekly basis and taper off as goals are reached, usually to every other week and then to once a month. Some clients choose to have check-in sessions a few times a year or come back as needed when events such as a loss or significant life change occur. 
        <br /><br />
        Although this is a typical therapy timeline, the scheduling can be increased or decreased depending upon the level of stress and conflict in your life. Therapy sessions are usually 50-55 minutes in length. You can expect to have homework such as activities, readings, or journaling. This helps incorporate the work you do in sessions into your everyday life.
      </>
    )
  },
  {
    question: "Do I need Insurance?",
    answer: (
      <>
        While we do accept insurance, we also welcome private pay clients. 
        <br /><br />
        To learn more about our Sliding Fee Scale, please refer to the <strong>Clients Tab</strong>.
      </>
    )
  },
  {
    question: "What insurances are accepted?",
    answer: (
      <>
        We offer a range of different insurance providers! 
        <ul>
          <li>Aetna</li>
          <li>Amerigroup</li>
          <li>BlueCross BlueShield</li>
          <li>Cigna</li>
          <li>Health Partners</li>
          <li>Humana</li>
          <li>Medicaid</li>
          <li>Medica</li>
          <li>Optum</li>
          <li>Preferred One</li>
          <li>UMR</li>
          <li>United Healthcare</li>
        </ul>
        <br /><br />
        To find more options if your insusrance is not currently offered, send us an email!  <a href="mailto:reception@northlandpsychological.com" style={{ color: 'inherit', textDecoration: 'none', marginLeft: '4px' }}>
        <strong>reception@northlandpsychological.com</strong>
      </a>
      </>
    )
  },
  {
    question: "Do I need a referral?",
    answer: (
      <>
        No referrals are necessary to schedule an appointment. 
        <br /><br />
        However, if you have been referred, please let us know how you heard of our clinic!
      </>
    )
  },
  {
    question: "How long will I be in therapy once I begin?",
    answer: (
      <>
        Therapy is provided on a need basis, not a standard schedule. We provide both short-term and long-term therapy to meet individual clients’ needs.
        <br /><br />
        Goals are set by you and the therapist. As these goals are met, the frequency and duration of therapy is reevaluated.
      </>
    )
  },
  {
    question: "What are the Privacy Practices?",
    answer: (
      <>
        <a href="/privacy-policy" style={{ color: 'inherit', textDecoration: 'none' }}>
          To view our privacy practices, click here!
        </a>
      </>
    )
  },
  {
    question: "How will I know if my insurance covers these services?",
    answer: (
      <>
        We know the importance of coverage to you. Therefore, we take great care in providing a service to you where we can obtain all your coverage benefits information prior to your first appointment.
        <br /><br />
        We provide accurate information in a time-sensitive manner. This eliminates any stresses or worries that are unnecessary to you, as our client. 
        <br /><br />
        We invite you to email our staff at <a href="mailto:reception@northlandpsychological.com">reception@northlandpsychological.com</a> or call us at <a href="tel:+12187296480">(218) 729-6480</a> and speak with them regarding your insurance benefits.
        <br /><br />
        However, some questions we cannot answer, therefore we encourage you to call your insurance company and ask them about your coverage!
      </>
    )
  },
  {
    question: "Who do I contact if I have a billing question?",
    answer: (
      <>
        Please contact <a href="mailto:reception@northlandpsychological.com">reception@northlandpsychological.com</a> for all of your billing questions. 
        <br /><br />
        If we cannot answer your questions, we will have someone from our billing team get back to you in a timely manner.
      </>
    )
  }
      ].map((faq, index) => (
        <Accordion
          key={index}
          expanded={expanded === index}
          onChange={handleChange(index)}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`panel${index + 1}-content`}
            id={`panel${index + 1}-header`}
          >
            <Typography>{faq.question}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>{faq.answer}</Typography>
          </AccordionDetails>
        </Accordion>
      ))}
    </Grid>
  );
};

export default FAQS;